import React from 'react';
import axios from 'axios';
import {handleChange} from '../utils/inputs';
import queryString from 'query-string';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(props.location.search);
    let {code} = params;
    this.state = {
      code,
      password: '',
      passwordConfirmation: ''
    };
    this.handleChange = handleChange.bind(this);
    console.log("ResetPassword.constructor props", props);
    console.log("ResetPassword.constructor this.state", this.state);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log("ResetPassword.handleSubmit event", event);
    const res = await axios({
      method: 'POST',
      url: process.env.REACT_APP_BACKEND_URL + '/auth/reset-password',
      data: this.state
    });
    console.log("ResetPassword.constructor handleSubmit res", res);
    if (this.props.updateUser && typeof this.props.updateUser === 'function') {
      this.props.updateUser(res.data);
    }
  }

  render() {
    const { password, passwordConfirmation } = this.state;
    return (
      <div className="reset-password">
        <h1 style={{textTransform: 'capitalize'}}>Reset Password</h1>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="password">Password</label>
            <input name="password" id="password" type="password" value={password} onChange={this.handleChange} />
          </div>
          <div>
            <label htmlFor="passwordConfirmation">Repeat Password</label>
            <input name="passwordConfirmation" id="passwordConfirmation" type="password" value={passwordConfirmation} onChange={this.handleChange} />
          </div>
          <button style={{textTransform: 'capitalize'}} type="submit">Reset Password</button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
