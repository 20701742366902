import React from 'react';
import axios from 'axios';
import {handleChange} from '../utils/inputs';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
    this.handleChange = handleChange.bind(this);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {email} = this.state;

    const data = {
      email
    }

    const userReq = await axios({
      method: 'POST',
      url: process.env.REACT_APP_BACKEND_URL + '/auth/forgot-password',
      data
    });

    console.log("ForgotPassword.handleSubmit userReq", userReq)
  }

  render() {
    const { email } = this.state;
    return (
      <div className="forgot-password">
        <h1 style={{textTransform: 'capitalize'}}>Forgot Password</h1>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input name="email" id="email" value={email} onChange={this.handleChange} />
          </div>
          <button style={{textTransform: 'capitalize'}} type="submit">Send me a password reset email!</button>
        </form>
      </div>
    );
  }
}

export default ForgotPassword;
