import React from 'react';

class ProfilePage extends React.Component {
  render() {
    return (
      <div className="ProfilePage">
        You can now return to the Villije app
      </div>
    );
  }
}

export default ProfilePage;
