import React from 'react';
import axios from 'axios';
import {handleChange} from '../utils/inputs';
import {
  Link
} from "react-router-dom";

class RegisterOrLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      mode: 'login'
    }
    this.url = {
      login: process.env.REACT_APP_BACKEND_URL + '/auth/local',
      register: process.env.REACT_APP_BACKEND_URL + '/auth/local/register'
    }
    this.handleChange = handleChange.bind(this);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const {email, password, mode} = this.state;
    console.log("RegisterOrLogin.handleSubmit mode", mode);
    console.log("RegisterOrLogin.handleSubmit url", this.url[mode]);

    let data;
    if (mode === 'login') {
      data = {
        identifier: email,
        password
      }
    } else {
      data = {
        email,
        password,
        username: email
      }
    }

    const userReq = await axios({
      method: 'POST',
      url: this.url[mode],
      data
    });

    console.log("RegisterOrLogin.handleSubmit userReq", userReq)
    if (this.props.updateUser && typeof this.props.updateUser === 'function') {
      this.props.updateUser(userReq.data);
    }
  }

  render() {
    const { email, password, mode } = this.state;
    return (
      <div className={mode}>
        <h1 style={{textTransform: 'capitalize'}}>{mode}</h1>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input name="email" id="email" value={email} onChange={this.handleChange} />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input name="password" id="password" type="password" value={password} onChange={this.handleChange} />
          </div>
          <button style={{textTransform: 'capitalize'}} type="submit">{mode}</button>
        </form>
        {mode === 'login' &&
          <button onClick={() => this.setState({mode: 'signup'})}>Want to signup instead?</button>
        }
        {mode === 'signup' &&
          <button onClick={() => this.setState({mode: 'login'})}>Want to login instead?</button>
        }
        <Link to="/forgot">Forgot password</Link>
      </div>
    );
  }
}

export default RegisterOrLogin;
