import React from 'react';
import logo from './villije.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import RegisterOrLogin from './components/RegisterOrLogin';
import ProfilePage from './components/ProfilePage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Success from './components/Success';

class App extends React.Component {
  state = {
    user: null
  }
  render() {
    const { user } = this.state;
    return (
      <Router>
        <div className="App">
          <nav>
            <Link to="/">
              <img src={logo} className="App-logo" alt="Villije" />
            </Link>
            {/* <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul> */}
          </nav>
          {!user &&
            <Switch>
              <Route exact path="/about">
                About
              </Route>
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route exact path="/resetpassword" render={(props) => <ResetPassword {...props} updateUser={(user) => this.setState({ user })} />} />
              <Route exact path="/success" component={Success} />

              <Route path="/">
                <RegisterOrLogin updateUser={(user) => this.setState({ user })} />
              </Route>
            </Switch>
          }
          {user &&
            <ProfilePage user={user} />
          }
        </div>
      </Router>
    );
  }
}

export default App;
