import React from 'react';

function Success(props) {
  return (
    <div className="Success">
      Thank you, you can now return to the Villije app.
    </div>
  );
}

export default Success;
